.feedback {
  @include border-radius(3px);
  border: solid 1px #D2D6DE;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  .user-feedback {
    margin-bottom: 5px;
    border-bottom: solid 1px #D2D6DE;
    font-size: small;
  }
  .content-feedback {
    padding: 0 5px;
    margin: 0;
  }
  .content-source {
    display: none;
  }
  margin-bottom: 10px;

  .feedback-stars {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}

.feedback-stars {
  font-size: larger;
  color: rgba(245,159,26,0.8);
  text-shadow: 1px 4px 6px #FFF,0 0 0 #000,1px 4px 6px #FFF;
  .disabled {
    color: #d8d8d8;
  }
  &.input i:hover {
    cursor: pointer;
  }
}