table.dataTable {
  clear: both;
  margin: 0.5em 0 !important;
  max-width: none !important;
  width: 100%;
  td, th {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  &.nowrap {
    th, td {
      white-space: nowrap;
    }
  }
}

div.dataTables_wrapper div {
  &.dataTables_length {
    label {
      float: left;
      text-align: left;
      margin-bottom: 0;
    }
    select {
      width: 75px;
      margin-bottom: 0;
    }
  }
  &.dataTables_filter {
    label {
      float: right;
      margin-bottom: 0;
    }
    input {
      display: inline-block !important;
      width: auto !important;
      margin-bottom: 0;
      margin-left: 0.5em;
    }
  }
  &.dataTables_info {
    padding-top: 2px;
  }
  &.dataTables_paginate {
    float: right;
    margin: 0;
  }
}

table.dataTable thead {
  th, td {
    padding-right: 1.5rem;
  }
  th:active, td:active {
    outline: none;
  }
  .sorting, .sorting_asc, .sorting_desc {
    cursor: pointer;
  }
  .sorting, .sorting_asc, .sorting_desc, .sorting_asc_disabled, .sorting_desc_disabled {
    background-repeat: no-repeat;
    background-position: center right;
  }
  .sorting {
    background-image: url("/img/sort_both.png");
  }
  .sorting_asc {
    background-image: url("/img/sort_asc.png");
  }
  .sorting_desc {
    background-image: url("/img/sort_desc.png");
  }
  .sorting_asc_disabled {
    background-image: url("/img/sort_asc_disabled.png");
  }
  .sorting_desc_disabled {
    background-image: url("/img/sort_desc_disabled.png");
  }
}

div {
  &.dataTables_scrollHead table {
    margin-bottom: 0 !important;
  }
  &.dataTables_scrollBody table {
    border-top: none;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    tbody tr:first-child {
      th, td {
        border-top: none;
      }
    }
  }
  &.dataTables_scrollFoot table {
    margin-top: 0 !important;
    border-top: none;
  }
}
