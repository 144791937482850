$topbar-breakpoint: 1024px;
$topbar-media-query: "only screen and (min-width: 1024px)";

#notifications-topbar {
  position: relative;
  .notifications-count {
    position: absolute;
    top:5px;
    right:25px;
    background-color: red;
    color: white;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;

    font-size: 10px;
    line-height: 16px;
    width: 16px;
    height: 16px;
    text-align: center;

    box-sizing: border-box;
    //padding-right: 1px;
    //padding-top: 1px;
  }
}

.top-bar-section .profile-selection {
  background-color: #00a0cd;
  a:not(.button) {
    background-color: transparent !important;
  }
  .dropdown li {
    //padding-left: 5px;
    box-sizing: border-box;
    &:hover {
      background-color: #222222 !important;
    }
    a {
      color: white;
    }
  }
  .inline {
    display: inline;
    //line-height: 40px;
    white-space: nowrap;
  }
  .name {
    background-color: #00a0cd;
    box-sizing: border-box;
    padding: 0 5px;
  }
  #menu-photo,.menu-photo {
    position: relative;
    top: 5px;
    width: 35px;
    height: 35px;
    background-size: 35px auto;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    a {
      padding: 15px;
    }
  }
  .welcome {
    display: inline-block;

    font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 0.8125rem;
    font-weight: normal;
    a {
      color: black;
    }
  }
  #menu-photo-add {
    background: url('/img/add-icon.png') center no-repeat;
    background-size: 30px auto;
  }
}