.call-comment {
  @include border-radius(3px);
  border: solid 1px #D2D6DE;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  .user-comment {
    margin-bottom: 5px;
    border-bottom: solid 1px #D2D6DE;
    font-size: small;
  }
  .content-comment {
    padding: 0 5px;
    margin: 0;
  }
  a.delete {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #d8d8d8;
    &:hover {
      color: #5e5e5e;
    }
  }
  margin-bottom: 10px;
}