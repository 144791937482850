.profile-feedback {
  padding-top: 10px;
}

.user-profile-item {
  @include border-radius(3px);
  border: solid 1px #D2D6DE;
  padding: 5px;
  box-sizing: border-box;
  position: relative;
  .user-profile-head {
    border-bottom: dotted 1px #D2D6DE;
    margin-bottom: 5px;
  }
  .user-profile-name {
    margin-bottom: 5px;
    font-size: small;
    vertical-align: middle;
    height: 40px;
  }
  .user-profile-photo {
    width: 40px;
    vertical-align: middle;
    .profile-photo {
      width: 30px;
      height: 30px;
      background-size: 30px auto;
      top: 0px;
    }
  }
  .user-details {
    padding: 0 5px;
    margin: 0;
    ul {
      display: inline;
      list-style: none;
      li {
        display: inline;
        border: solid 1px #D2D6DE;
        margin-right: 5px;
        padding: 1px 4px;
      }
    }
  }
  .user-feedback {
    width: 150px;
  }
  .user-actions {
    a {
      color: #d8d8d8;
      &:hover {
        color: #008cba;
      }
      &.active {
        color: #008cba;
      }
    }
  }
  margin-bottom: 10px;

}

.profile-photo {
  position: relative;
  top: 5px;
  width: 35px;
  height: 35px;
  background-size: 35px auto;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}