@import 'vendor/normalize';
//@import 'vendor/jquery-ui';
//@import 'vendor/jquery-ui.theme';
//@import 'vendor/foundation';
@import 'vendor/foundation-icons';
@import 'vendor/foundation-datepicker';
//@import 'vendor/font-awesome';
@import 'vendor/dataTables.foundation';
@import 'vendor/select2';

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@import 'imports/call-comment';
@import 'imports/answer';
@import 'imports/profile';
@import 'imports/feedback';
@import 'imports/topbar';

// OVERRIDE DI FOUNDATION

.container {
  margin-top: 20px;
}

table.editable tr,
table.picktable tr {
  &:not(.disabled) {
    cursor: pointer;
    &:hover td {
      background-color: lightsteelblue;
    }

    th.selector > input, td.selector > input {
      margin: 0;
      vertical-align: middle;
    }
    &.red {
      td,a {
        color: #f04124;
      }
    }
  }
  &.disabled {
    font-style: italic;
    td {
      background-color: #efefef;
    }
  }
}

button, a.button {
  min-width: 100px;
  &.delete {
    background-color: #ff8b39;
  }
}

#side-actions {
  a.button {
    width: 100%;
  }
}

.button.disabled, .button[disabled] {
  opacity: 0.5;
}

.select2-container {
  margin: 0 0 1rem;
}

form {
  div.has-error {
    color: red;
    label {
      color: red;
    }
    input,
    .select2-container.select2 {
      border-color: red;
      margin-bottom: 0;
    }
    .select2-container.select2 a,
    .select2-container-multi ul {
      border-color: red;
    }

    .help-block {
      margin: 0 0 0.5rem;
      font-size: small;
    }
  }
}

.likeP {
  margin-bottom: 1.25rem;
}

.rounded-panel {
  border: solid 1px #D2D6DE;
}

.target {
  background-color: yellow;
}

#notifications-topbar {
  li:not(.has-form) a:not(.button).notification {
    line-height: 15px;
    padding: 12px 0.9375rem;
  }
  .dropdown {
    max-height: 250px;
    overflow-y: auto;
    li a {
      white-space: normal;
    }
  }
}

$trans-duration: 250ms;
$trans-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
$pane-width: 400px;

.overpane {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(239, 239, 239, 0.9);
  padding: 2rem;
  z-index: 2000;
  transition: $trans-duration $trans-timing-function transform;
  transform: translateY(100%);
  &.show {
    transform: translateY(0);
  }
  .row {
    margin: 0 auto;
    max-width: 62.5rem;
    width: 100%;
  }
}

.file-upload {
  position: relative;
  overflow: hidden;
  margin: 10px;
}

.file-upload input.file-input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.addToCart a {
  font-size: large;
}

div {
  .table {
    display: table;
    width: 100%;
    .row {
      display: table-row;
      .cell {
        display: table-cell;
        box-sizing: border-box;
        padding: 10px;
        &.text-right {
          text-align: right;
        }
        &.text-center {
          text-align: center;
        }
        &.no-padding {
          padding: 0;
        }
      }
    }
  }
}

.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}


.select2 {
  &.icon {
    width: 20px;
  }
}

.disclaim {
  font-size: smaller;
}

span.disabled {
  color: lightgrey;
}

.border {
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  padding:5px;
}

.orders {
  h4 {
    display: inline;
    margin-right: 10px;
  }
  .order {
    display: inline;
    margin-right: 10px;
    &.disabled a {
      color: #c5c5c5;
    }
  }

}

.dashboard-icon
{
  font-size: 7.5em;
}

.not-charged {
  td {
    color: lightgrey;
  }
}