.answer {
  @include border-radius(3px);
  border: solid 1px #D2D6DE;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  &.waiting {
    border: solid 2px #f04124;
  }
  &.matched {
    border: solid 2px #d2d800;
  }
  &.closed {
    border: solid 2px #7fd865;
  }
  .user-answer {
    margin-bottom: 5px;
    border-bottom: solid 1px #D2D6DE;
    font-size: small;
  }
  .content-answer {
    padding: 0 5px;
    margin: 0;
  }
  a{
    &.match {
      position: absolute;
      right: 5px;
      top: 5px;
      color: #d8d8d8;
      &:hover {
        color: #d2d800;
      }
      &.matched {
        &.disabled {
          color: #d2d800;
          cursor: default;
          &.waiting {
            color: #f04124;
          }
        }
      }
    }
    &.closedeal {
      position: absolute;
      right: 25px;
      top: 5px;
      color: #d8d8d8;
      &:hover {
        color: #d2d800;
      }
      &.closed.disabled {
        color: #7fd865;
        cursor: default;
      }
    }
  }
  margin-bottom: 10px;
}