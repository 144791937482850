//colors
$white: white;
$color_silver_chalice_approx: #aaa;
$color_tundora_approx: #444;
$color_mountain_mist_approx: #999;
$color_suva_gray_approx: #888;
$color_gallery_approx: #eee;
$color_mercury_approx: #e4e4e4;
$color_mine_shaft_approx: #333;
$black:  black;
$color_alto_approx: #ddd;
$color_cornflower_blue_approx: #5897fb;
$color_black_haze_approx: #f6f6f6;
$color_celeste_approx: #cccccc;
$color_fuscous_gray_approx: #555;
$gray: grey;
$color_royal_blue_approx: #3875d7;

.select2-container {
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  .select2-selection--single {
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    //Instead of the line below you could use @include user-select($select)
    user-select: none;
    .select2-selection__rendered {
      display: block;
      padding-left: 8px;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .select2-selection--multiple {
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    //Instead of the line below you could use @include user-select($select)
    user-select: none;
    .select2-selection__rendered {
      display: inline-block;
      overflow: hidden;
      padding-left: 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .select2-search--inline {
    float: left;
    .select2-search__field {
      //Instead of the line below you could use @include box-sizing($bs)
      box-sizing: border-box;
      border: none;
      font-size: 100%;
      margin-top: 5px;
    }
    .select2-search__field::-webkit-search-cancel-button {
    }
  }
}
.select2-dropdown {
  background-color: $white;
  border: 1px solid $color_silver_chalice_approx;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 4px;
  //Instead of the line below you could use @include box-sizing($bs)
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}
.select2-results {
  display: block;
}
.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}
.select2-results__option {
  padding: 6px;
  //Instead of the line below you could use @include user-select($select)
  user-select: none;
}
.select2-results__option[aria-selected] {
  cursor: pointer;
}
.select2-container--open {
  .select2-dropdown {
    left: 0;
  }
  .select2-dropdown--above {
    border-bottom: none;
    //Instead of the line below you could use @include border-bottom-left-radius($radius)
    border-bottom-left-radius: 0;
    //Instead of the line below you could use @include border-bottom-right-radius($radius)
    border-bottom-right-radius: 0;
  }
  .select2-dropdown--below {
    border-top: none;
    //Instead of the line below you could use @include border-top-left-radius($radius)
    border-top-left-radius: 0;
    //Instead of the line below you could use @include border-top-right-radius($radius)
    border-top-right-radius: 0;
  }
}
.select2-search--dropdown {
  display: block;
  padding: 4px;
  .select2-search__field {
    padding: 4px;
    width: 100%;
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
  }
  .select2-search__field::-webkit-search-cancel-button {
  }
  &.select2-search--hide {
    display: none;
  }
}
.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: $white;
  filter: alpha(opacity=0);
}
.select2-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.select2-container--default {
  .select2-selection--single {
    background-color: $white;
    border: 1px solid $color_silver_chalice_approx;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 4px;
    .select2-selection__rendered {
      color: $color_tundora_approx;
      line-height: 28px;
    }
    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
    }
    .select2-selection__placeholder {
      color: $color_mountain_mist_approx;
    }
    .select2-selection__arrow {
      height: 26px;
      position: absolute;
      top: 1px;
      right: 1px;
      width: 20px;
      b {
        border-color: $color_suva_gray_approx transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        height: 0;
        left: 50%;
        margin-left: -4px;
        margin-top: -2px;
        position: absolute;
        top: 50%;
        width: 0;
      }
    }
  }
  &.select2-container--disabled {
    .select2-selection--single {
      background-color: $color_gallery_approx;
      cursor: default;
      .select2-selection__clear {
        display: none;
      }
    }
    .select2-selection--multiple {
      background-color: $color_gallery_approx;
      cursor: default;
    }
    .select2-selection__choice__remove {
      display: none;
    }
  }
  &.select2-container--open {
    &.select2-container--above {
      .select2-selection--single {
        //Instead of the line below you could use @include border-top-left-radius($radius)
        border-top-left-radius: 0;
        //Instead of the line below you could use @include border-top-right-radius($radius)
        border-top-right-radius: 0;
      }
      .select2-selection--multiple {
        //Instead of the line below you could use @include border-top-left-radius($radius)
        border-top-left-radius: 0;
        //Instead of the line below you could use @include border-top-right-radius($radius)
        border-top-right-radius: 0;
      }
    }
    &.select2-container--below {
      .select2-selection--single {
        //Instead of the line below you could use @include border-bottom-left-radius($radius)
        border-bottom-left-radius: 0;
        //Instead of the line below you could use @include border-bottom-right-radius($radius)
        border-bottom-right-radius: 0;
      }
      .select2-selection--multiple {
        //Instead of the line below you could use @include border-bottom-left-radius($radius)
        border-bottom-left-radius: 0;
        //Instead of the line below you could use @include border-bottom-right-radius($radius)
        border-bottom-right-radius: 0;
      }
    }
    .select2-selection--single .select2-selection__arrow b {
      border-color: transparent transparent $color_suva_gray_approx transparent;
      border-width: 0 4px 5px 4px;
    }
  }
  .select2-selection--multiple {
    background-color: $white;
    border: 1px solid $color_silver_chalice_approx;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 4px;
    cursor: text;
    .select2-selection__rendered {
      //Instead of the line below you could use @include box-sizing($bs)
      box-sizing: border-box;
      list-style: none;
      margin: 0;
      padding: 0 5px;
      width: 100%;
    }
    .select2-selection__placeholder {
      color: $color_mountain_mist_approx;
      margin-top: 5px;
      float: left;
    }
    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
      margin-top: 5px;
      margin-right: 10px;
    }
    .select2-selection__choice {
      background-color: $color_mercury_approx;
      border: 1px solid $color_silver_chalice_approx;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 4px;
      cursor: default;
      float: left;
      margin-right: 5px;
      margin-top: 5px;
      padding: 0 5px;
    }
    .select2-selection__choice__remove {
      color: $color_mountain_mist_approx;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      margin-right: 2px;
      &:hover {
        color: $color_mine_shaft_approx;
      }
    }
  }
  .select2-results__option[role=group] {
    padding: 0;
  }
  .select2-results__option[aria-disabled=true] {
    color: $color_mountain_mist_approx;
  }
  .select2-results__option[aria-selected=true] {
    background-color: $color_alto_approx;
  }
  .select2-results__option--highlighted[aria-selected] {
    background-color: $color_cornflower_blue_approx;
    color: $white;
  }
  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }
  &.select2-container--focus .select2-selection--multiple {
    border: solid $black 1px;
    outline: 0;
  }
  .select2-search--dropdown .select2-search__field {
    border: 1px solid $color_silver_chalice_approx;
  }
  .select2-search--inline .select2-search__field {
    background: transparent;
    border: none;
    outline: 0;
  }
  .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
  }
  .select2-results__option .select2-results__option {
    padding-left: 1em;
    .select2-results__group {
      padding-left: 0;
    }
    .select2-results__option {
      margin-left: -1em;
      padding-left: 2em;
      .select2-results__option {
        margin-left: -2em;
        padding-left: 3em;
        .select2-results__option {
          margin-left: -3em;
          padding-left: 4em;
          .select2-results__option {
            margin-left: -4em;
            padding-left: 5em;
            .select2-results__option {
              margin-left: -5em;
              padding-left: 6em;
            }
          }
        }
      }
    }
  }
}
.select2-container--default[dir="rtl"] {
  .select2-selection--single {
    .select2-selection__clear {
      float: left;
    }
    .select2-selection__arrow {
      left: 1px;
      right: auto;
    }
  }
  .select2-selection--multiple {
    .select2-selection__choice {
      float: right;
      margin-left: 5px;
      margin-right: auto;
    }
    .select2-selection__placeholder {
      float: right;
    }
    .select2-selection__choice__remove {
      margin-left: 2px;
      margin-right: auto;
    }
  }
}
.select2-container--classic {
  .select2-selection--single {
    background-color: $color_black_haze_approx;
    border: 1px solid $color_silver_chalice_approx;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 4px;
    outline: 0;
    background-image: linear-gradient(to bottom, $white 50%, $color_gallery_approx 100%);
    background-repeat: repeat-x;
    filter: progid:dximagetransform.microsoft.gradient(startcolorstr='$white', endcolorstr='$color_gallery_approx', gradienttype=0);
    &:focus {
      border: 1px solid $color_cornflower_blue_approx;
    }
    .select2-selection__rendered {
      color: $color_tundora_approx;
      line-height: 28px;
    }
    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
      margin-right: 10px;
    }
    .select2-selection__placeholder {
      color: $color_mountain_mist_approx;
    }
    .select2-selection__arrow {
      background-color: $color_alto_approx;
      border: none;
      border-left: 1px solid $color_silver_chalice_approx;
      //Instead of the line below you could use @include border-top-right-radius($radius)
      border-top-right-radius: 4px;
      //Instead of the line below you could use @include border-bottom-right-radius($radius)
      border-bottom-right-radius: 4px;
      height: 26px;
      position: absolute;
      top: 1px;
      right: 1px;
      width: 20px;
      background-image: linear-gradient(to bottom, $color_gallery_approx 50%, $color_celeste_approx 100%);
      background-repeat: repeat-x;
      filter: progid:dximagetransform.microsoft.gradient(startcolorstr='$color_gallery_approx', endcolorstr='$color_celeste_approx', gradienttype=0);
      b {
        border-color: $color_suva_gray_approx transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        height: 0;
        left: 50%;
        margin-left: -4px;
        margin-top: -2px;
        position: absolute;
        top: 50%;
        width: 0;
      }
    }
  }
  &.select2-container--open {
    .select2-selection--single {
      border: 1px solid $color_cornflower_blue_approx;
      .select2-selection__arrow {
        background: transparent;
        border: none;
        b {
          border-color: transparent transparent $color_suva_gray_approx transparent;
          border-width: 0 4px 5px 4px;
        }
      }
    }
    &.select2-container--above {
      .select2-selection--single {
        border-top: none;
        //Instead of the line below you could use @include border-top-left-radius($radius)
        border-top-left-radius: 0;
        //Instead of the line below you could use @include border-top-right-radius($radius)
        border-top-right-radius: 0;
        background-image: linear-gradient(to bottom, $white 0, $color_gallery_approx 50%);
        background-repeat: repeat-x;
        filter: progid:dximagetransform.microsoft.gradient(startcolorstr='$white', endcolorstr='$color_gallery_approx', gradienttype=0);
      }
      .select2-selection--multiple {
        border-top: none;
        //Instead of the line below you could use @include border-top-left-radius($radius)
        border-top-left-radius: 0;
        //Instead of the line below you could use @include border-top-right-radius($radius)
        border-top-right-radius: 0;
      }
    }
    &.select2-container--below {
      .select2-selection--single {
        border-bottom: none;
        //Instead of the line below you could use @include border-bottom-left-radius($radius)
        border-bottom-left-radius: 0;
        //Instead of the line below you could use @include border-bottom-right-radius($radius)
        border-bottom-right-radius: 0;
        background-image: linear-gradient(to bottom, $color_gallery_approx 50%, $white 100%);
        background-repeat: repeat-x;
        filter: progid:dximagetransform.microsoft.gradient(startcolorstr='$color_gallery_approx', endcolorstr='$white', gradienttype=0);
      }
      .select2-selection--multiple {
        border-bottom: none;
        //Instead of the line below you could use @include border-bottom-left-radius($radius)
        border-bottom-left-radius: 0;
        //Instead of the line below you could use @include border-bottom-right-radius($radius)
        border-bottom-right-radius: 0;
      }
    }
    .select2-selection--multiple {
      border: 1px solid $color_cornflower_blue_approx;
    }
    .select2-dropdown {
      border-color: $color_cornflower_blue_approx;
    }
  }
  .select2-selection--multiple {
    background-color: $white;
    border: 1px solid $color_silver_chalice_approx;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 4px;
    cursor: text;
    outline: 0;
    &:focus {
      border: 1px solid $color_cornflower_blue_approx;
    }
    .select2-selection__rendered {
      list-style: none;
      margin: 0;
      padding: 0 5px;
    }
    .select2-selection__clear {
      display: none;
    }
    .select2-selection__choice {
      background-color: $color_mercury_approx;
      border: 1px solid $color_silver_chalice_approx;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 4px;
      cursor: default;
      float: left;
      margin-right: 5px;
      margin-top: 5px;
      padding: 0 5px;
    }
    .select2-selection__choice__remove {
      color: $color_suva_gray_approx;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      margin-right: 2px;
      &:hover {
        color: $color_fuscous_gray_approx;
      }
    }
  }
  .select2-dropdown {
    background-color: $white;
    border: 1px solid transparent;
  }
  .select2-dropdown--above {
    border-bottom: none;
  }
  .select2-dropdown--below {
    border-top: none;
  }
  .select2-results__option[role=group] {
    padding: 0;
  }
  .select2-results__option[aria-disabled=true] {
    color: $gray;
  }
  .select2-results__option--highlighted[aria-selected] {
    background-color: $color_royal_blue_approx;
    color: $white;
  }
  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }
  .select2-search--dropdown .select2-search__field {
    border: 1px solid $color_silver_chalice_approx;
    outline: 0;
  }
  .select2-search--inline .select2-search__field {
    outline: 0;
  }
  .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
  }
}
.select2-container--classic[dir="rtl"] {
  .select2-selection--single {
    .select2-selection__clear {
      float: left;
    }
    .select2-selection__arrow {
      border: none;
      border-right: 1px solid $color_silver_chalice_approx;
      //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
      border-radius: 0;
      //Instead of the line below you could use @include border-top-left-radius($radius)
      border-top-left-radius: 4px;
      //Instead of the line below you could use @include border-bottom-left-radius($radius)
      border-bottom-left-radius: 4px;
      left: 1px;
      right: auto;
    }
  }
  .select2-selection--multiple {
    .select2-selection__choice {
      float: right;
      margin-left: 5px;
      margin-right: auto;
    }
    .select2-selection__choice__remove {
      margin-left: 2px;
      margin-right: auto;
    }
  }
}
.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}