sub, sup, .datepicker td, .datepicker th {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

html {
  font-family: sans-serif; }
  html input[type="button"] {
    cursor: pointer; }
  html input[disabled] {
    cursor: default; }

body {
  margin: 0; }

article {
  display: block; }

aside {
  display: block; }

details {
  display: block; }

figcaption {
  display: block; }

figure {
  display: block;
  margin: 1em 40px; }

footer {
  display: block; }

header {
  display: block; }

hgroup {
  display: block; }

main {
  display: block; }

menu {
  display: block; }

nav {
  display: block; }

section {
  display: block; }

summary {
  display: block; }

audio {
  display: inline-block;
  vertical-align: baseline; }
  audio:not([controls]) {
    display: none;
    height: 0; }

canvas {
  display: inline-block;
  vertical-align: baseline; }

progress {
  display: inline-block;
  vertical-align: baseline; }

video {
  display: inline-block;
  vertical-align: baseline; }

[hidden] {
  display: none; }

template {
  display: none; }

a {
  background-color: transparent; }
  a:active {
    outline: 0; }
  a:hover {
    outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b {
  font-weight: bold; }

strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border: 0; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto;
  font-family: monospace, monospace;
  font-size: 1em; }

code {
  font-family: monospace, monospace;
  font-size: 1em; }

kbd {
  font-family: monospace, monospace;
  font-size: 1em; }

samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button {
  overflow: visible;
  text-transform: none;
  cursor: pointer; }

input {
  line-height: normal; }

optgroup {
  font-weight: bold; }

select {
  text-transform: none; }

textarea {
  overflow: auto; }

input[type="reset"] {
  cursor: pointer; }

input[type="submit"] {
  cursor: pointer; }

button[disabled] {
  cursor: default; }

button::-moz-focus-inner {
  border: 0;
  padding: 0; }

input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button {
  height: auto; }

input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  box-sizing: content-box; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td {
  padding: 0; }

th {
  padding: 0; }

svg:not(:root) {
  overflow: hidden; }

@font-face {
  font-family: foundation-icons;
  src: url("/fonts/foundation-icons.eot");
  src: url("/fonts/foundation-icons.eot?#iefix") format("embedded-opentype"), url("/fonts/foundation-icons.woff") format("woff"), url("/fonts/foundation-icons.ttf") format("truetype"), url("/fonts/foundation-icons.svg#fontcustom") format("svg");
  font-weight: normal;
  font-style: normal; }

.fi-address-book:before,
.fi-alert:before,
.fi-align-center:before,
.fi-align-justify:before,
.fi-align-left:before,
.fi-align-right:before,
.fi-anchor:before,
.fi-annotate:before,
.fi-archive:before,
.fi-arrow-down:before,
.fi-arrow-left:before,
.fi-arrow-right:before,
.fi-arrow-up:before,
.fi-arrows-compress:before,
.fi-arrows-expand:before,
.fi-arrows-in:before,
.fi-arrows-out:before,
.fi-asl:before,
.fi-asterisk:before,
.fi-at-sign:before,
.fi-background-color:before,
.fi-battery-empty:before,
.fi-battery-full:before,
.fi-battery-half:before,
.fi-bitcoin-circle:before,
.fi-bitcoin:before,
.fi-blind:before,
.fi-bluetooth:before,
.fi-bold:before,
.fi-book-bookmark:before,
.fi-book:before,
.fi-bookmark:before,
.fi-braille:before,
.fi-burst-new:before,
.fi-burst-sale:before,
.fi-burst:before,
.fi-calendar:before,
.fi-camera:before,
.fi-check:before,
.fi-checkbox:before,
.fi-clipboard-notes:before,
.fi-clipboard-pencil:before,
.fi-clipboard:before,
.fi-clock:before,
.fi-closed-caption:before,
.fi-cloud:before,
.fi-comment-minus:before,
.fi-comment-quotes:before,
.fi-comment-video:before,
.fi-comment:before,
.fi-comments:before,
.fi-compass:before,
.fi-contrast:before,
.fi-credit-card:before,
.fi-crop:before,
.fi-crown:before,
.fi-css3:before,
.fi-database:before,
.fi-die-five:before,
.fi-die-four:before,
.fi-die-one:before,
.fi-die-six:before,
.fi-die-three:before,
.fi-die-two:before,
.fi-dislike:before,
.fi-dollar-bill:before,
.fi-dollar:before,
.fi-download:before,
.fi-eject:before,
.fi-elevator:before,
.fi-euro:before,
.fi-eye:before,
.fi-fast-forward:before,
.fi-female-symbol:before,
.fi-female:before,
.fi-filter:before,
.fi-first-aid:before,
.fi-flag:before,
.fi-folder-add:before,
.fi-folder-lock:before,
.fi-folder:before,
.fi-foot:before,
.fi-foundation:before,
.fi-graph-bar:before,
.fi-graph-horizontal:before,
.fi-graph-pie:before,
.fi-graph-trend:before,
.fi-guide-dog:before,
.fi-hearing-aid:before,
.fi-heart:before,
.fi-home:before,
.fi-html5:before,
.fi-indent-less:before,
.fi-indent-more:before,
.fi-info:before,
.fi-italic:before,
.fi-key:before,
.fi-laptop:before,
.fi-layout:before,
.fi-lightbulb:before,
.fi-like:before,
.fi-link:before,
.fi-list-bullet:before,
.fi-list-number:before,
.fi-list-thumbnails:before,
.fi-list:before,
.fi-lock:before,
.fi-loop:before,
.fi-magnifying-glass:before,
.fi-mail:before,
.fi-male-female:before,
.fi-male-symbol:before,
.fi-male:before,
.fi-map:before,
.fi-marker:before,
.fi-megaphone:before,
.fi-microphone:before,
.fi-minus-circle:before,
.fi-minus:before,
.fi-mobile-signal:before,
.fi-mobile:before,
.fi-monitor:before,
.fi-mountains:before,
.fi-music:before,
.fi-next:before,
.fi-no-dogs:before,
.fi-no-smoking:before,
.fi-page-add:before,
.fi-page-copy:before,
.fi-page-csv:before,
.fi-page-delete:before,
.fi-page-doc:before,
.fi-page-edit:before,
.fi-page-export-csv:before,
.fi-page-export-doc:before,
.fi-page-export-pdf:before,
.fi-page-export:before,
.fi-page-filled:before,
.fi-page-multiple:before,
.fi-page-pdf:before,
.fi-page-remove:before,
.fi-page-search:before,
.fi-page:before,
.fi-paint-bucket:before,
.fi-paperclip:before,
.fi-pause:before,
.fi-paw:before,
.fi-paypal:before,
.fi-pencil:before,
.fi-photo:before,
.fi-play-circle:before,
.fi-play-video:before,
.fi-play:before,
.fi-plus:before,
.fi-pound:before,
.fi-power:before,
.fi-previous:before,
.fi-price-tag:before,
.fi-pricetag-multiple:before,
.fi-print:before,
.fi-prohibited:before,
.fi-projection-screen:before,
.fi-puzzle:before,
.fi-quote:before,
.fi-record:before,
.fi-refresh:before,
.fi-results-demographics:before,
.fi-results:before,
.fi-rewind-ten:before,
.fi-rewind:before,
.fi-rss:before,
.fi-safety-cone:before,
.fi-save:before,
.fi-share:before,
.fi-sheriff-badge:before,
.fi-shield:before,
.fi-shopping-bag:before,
.fi-shopping-cart:before,
.fi-shuffle:before,
.fi-skull:before,
.fi-social-500px:before,
.fi-social-adobe:before,
.fi-social-amazon:before,
.fi-social-android:before,
.fi-social-apple:before,
.fi-social-behance:before,
.fi-social-bing:before,
.fi-social-blogger:before,
.fi-social-delicious:before,
.fi-social-designer-news:before,
.fi-social-deviant-art:before,
.fi-social-digg:before,
.fi-social-dribbble:before,
.fi-social-drive:before,
.fi-social-dropbox:before,
.fi-social-evernote:before,
.fi-social-facebook:before,
.fi-social-flickr:before,
.fi-social-forrst:before,
.fi-social-foursquare:before,
.fi-social-game-center:before,
.fi-social-github:before,
.fi-social-google-plus:before,
.fi-social-hacker-news:before,
.fi-social-hi5:before,
.fi-social-instagram:before,
.fi-social-joomla:before,
.fi-social-lastfm:before,
.fi-social-linkedin:before,
.fi-social-medium:before,
.fi-social-myspace:before,
.fi-social-orkut:before,
.fi-social-path:before,
.fi-social-picasa:before,
.fi-social-pinterest:before,
.fi-social-rdio:before,
.fi-social-reddit:before,
.fi-social-skillshare:before,
.fi-social-skype:before,
.fi-social-smashing-mag:before,
.fi-social-snapchat:before,
.fi-social-spotify:before,
.fi-social-squidoo:before,
.fi-social-stack-overflow:before,
.fi-social-steam:before,
.fi-social-stumbleupon:before,
.fi-social-treehouse:before,
.fi-social-tumblr:before,
.fi-social-twitter:before,
.fi-social-vimeo:before,
.fi-social-windows:before,
.fi-social-xbox:before,
.fi-social-yahoo:before,
.fi-social-yelp:before,
.fi-social-youtube:before,
.fi-social-zerply:before,
.fi-social-zurb:before,
.fi-sound:before,
.fi-star:before,
.fi-stop:before,
.fi-strikethrough:before,
.fi-subscript:before,
.fi-superscript:before,
.fi-tablet-landscape:before,
.fi-tablet-portrait:before,
.fi-target-two:before,
.fi-target:before,
.fi-telephone-accessible:before,
.fi-telephone:before,
.fi-text-color:before,
.fi-thumbnails:before,
.fi-ticket:before,
.fi-torso-business:before,
.fi-torso-female:before,
.fi-torso:before,
.fi-torsos-all-female:before,
.fi-torsos-all:before,
.fi-torsos-female-male:before,
.fi-torsos-male-female:before,
.fi-torsos:before,
.fi-trash:before,
.fi-trees:before,
.fi-trophy:before,
.fi-underline:before,
.fi-universal-access:before,
.fi-unlink:before,
.fi-unlock:before,
.fi-upload-cloud:before,
.fi-upload:before,
.fi-usb:before,
.fi-video:before,
.fi-volume-none:before,
.fi-volume-strike:before,
.fi-volume:before,
.fi-web:before,
.fi-wheelchair:before,
.fi-widget:before,
.fi-wrench:before,
.fi-x-circle:before,
.fi-x:before,
.fi-yen:before,
.fi-zoom-in:before,
.fi-zoom-out:before {
  font-family: "foundation-icons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  text-decoration: inherit;
  margin-right: 5px; }

.fi-address-book:before {
  content: "\f100"; }

.fi-alert:before {
  content: "\f101"; }

.fi-align-center:before {
  content: "\f102"; }

.fi-align-justify:before {
  content: "\f103"; }

.fi-align-left:before {
  content: "\f104"; }

.fi-align-right:before {
  content: "\f105"; }

.fi-anchor:before {
  content: "\f106"; }

.fi-annotate:before {
  content: "\f107"; }

.fi-archive:before {
  content: "\f108"; }

.fi-arrow-down:before {
  content: "\f109"; }

.fi-arrow-left:before {
  content: "\f10a"; }

.fi-arrow-right:before {
  content: "\f10b"; }

.fi-arrow-up:before {
  content: "\f10c"; }

.fi-arrows-compress:before {
  content: "\f10d"; }

.fi-arrows-expand:before {
  content: "\f10e"; }

.fi-arrows-in:before {
  content: "\f10f"; }

.fi-arrows-out:before {
  content: "\f110"; }

.fi-asl:before {
  content: "\f111"; }

.fi-asterisk:before {
  content: "\f112"; }

.fi-at-sign:before {
  content: "\f113"; }

.fi-background-color:before {
  content: "\f114"; }

.fi-battery-empty:before {
  content: "\f115"; }

.fi-battery-full:before {
  content: "\f116"; }

.fi-battery-half:before {
  content: "\f117"; }

.fi-bitcoin-circle:before {
  content: "\f118"; }

.fi-bitcoin:before {
  content: "\f119"; }

.fi-blind:before {
  content: "\f11a"; }

.fi-bluetooth:before {
  content: "\f11b"; }

.fi-bold:before {
  content: "\f11c"; }

.fi-book-bookmark:before {
  content: "\f11d"; }

.fi-book:before {
  content: "\f11e"; }

.fi-bookmark:before {
  content: "\f11f"; }

.fi-braille:before {
  content: "\f120"; }

.fi-burst-new:before {
  content: "\f121"; }

.fi-burst-sale:before {
  content: "\f122"; }

.fi-burst:before {
  content: "\f123"; }

.fi-calendar:before {
  content: "\f124"; }

.fi-camera:before {
  content: "\f125"; }

.fi-check:before {
  content: "\f126"; }

.fi-checkbox:before {
  content: "\f127"; }

.fi-clipboard-notes:before {
  content: "\f128"; }

.fi-clipboard-pencil:before {
  content: "\f129"; }

.fi-clipboard:before {
  content: "\f12a"; }

.fi-clock:before {
  content: "\f12b"; }

.fi-closed-caption:before {
  content: "\f12c"; }

.fi-cloud:before {
  content: "\f12d"; }

.fi-comment-minus:before {
  content: "\f12e"; }

.fi-comment-quotes:before {
  content: "\f12f"; }

.fi-comment-video:before {
  content: "\f130"; }

.fi-comment:before {
  content: "\f131"; }

.fi-comments:before {
  content: "\f132"; }

.fi-compass:before {
  content: "\f133"; }

.fi-contrast:before {
  content: "\f134"; }

.fi-credit-card:before {
  content: "\f135"; }

.fi-crop:before {
  content: "\f136"; }

.fi-crown:before {
  content: "\f137"; }

.fi-css3:before {
  content: "\f138"; }

.fi-database:before {
  content: "\f139"; }

.fi-die-five:before {
  content: "\f13a"; }

.fi-die-four:before {
  content: "\f13b"; }

.fi-die-one:before {
  content: "\f13c"; }

.fi-die-six:before {
  content: "\f13d"; }

.fi-die-three:before {
  content: "\f13e"; }

.fi-die-two:before {
  content: "\f13f"; }

.fi-dislike:before {
  content: "\f140"; }

.fi-dollar-bill:before {
  content: "\f141"; }

.fi-dollar:before {
  content: "\f142"; }

.fi-download:before {
  content: "\f143"; }

.fi-eject:before {
  content: "\f144"; }

.fi-elevator:before {
  content: "\f145"; }

.fi-euro:before {
  content: "\f146"; }

.fi-eye:before {
  content: "\f147"; }

.fi-fast-forward:before {
  content: "\f148"; }

.fi-female-symbol:before {
  content: "\f149"; }

.fi-female:before {
  content: "\f14a"; }

.fi-filter:before {
  content: "\f14b"; }

.fi-first-aid:before {
  content: "\f14c"; }

.fi-flag:before {
  content: "\f14d"; }

.fi-folder-add:before {
  content: "\f14e"; }

.fi-folder-lock:before {
  content: "\f14f"; }

.fi-folder:before {
  content: "\f150"; }

.fi-foot:before {
  content: "\f151"; }

.fi-foundation:before {
  content: "\f152"; }

.fi-graph-bar:before {
  content: "\f153"; }

.fi-graph-horizontal:before {
  content: "\f154"; }

.fi-graph-pie:before {
  content: "\f155"; }

.fi-graph-trend:before {
  content: "\f156"; }

.fi-guide-dog:before {
  content: "\f157"; }

.fi-hearing-aid:before {
  content: "\f158"; }

.fi-heart:before {
  content: "\f159"; }

.fi-home:before {
  content: "\f15a"; }

.fi-html5:before {
  content: "\f15b"; }

.fi-indent-less:before {
  content: "\f15c"; }

.fi-indent-more:before {
  content: "\f15d"; }

.fi-info:before {
  content: "\f15e"; }

.fi-italic:before {
  content: "\f15f"; }

.fi-key:before {
  content: "\f160"; }

.fi-laptop:before {
  content: "\f161"; }

.fi-layout:before {
  content: "\f162"; }

.fi-lightbulb:before {
  content: "\f163"; }

.fi-like:before {
  content: "\f164"; }

.fi-link:before {
  content: "\f165"; }

.fi-list-bullet:before {
  content: "\f166"; }

.fi-list-number:before {
  content: "\f167"; }

.fi-list-thumbnails:before {
  content: "\f168"; }

.fi-list:before {
  content: "\f169"; }

.fi-lock:before {
  content: "\f16a"; }

.fi-loop:before {
  content: "\f16b"; }

.fi-magnifying-glass:before {
  content: "\f16c"; }

.fi-mail:before {
  content: "\f16d"; }

.fi-male-female:before {
  content: "\f16e"; }

.fi-male-symbol:before {
  content: "\f16f"; }

.fi-male:before {
  content: "\f170"; }

.fi-map:before {
  content: "\f171"; }

.fi-marker:before {
  content: "\f172"; }

.fi-megaphone:before {
  content: "\f173"; }

.fi-microphone:before {
  content: "\f174"; }

.fi-minus-circle:before {
  content: "\f175"; }

.fi-minus:before {
  content: "\f176"; }

.fi-mobile-signal:before {
  content: "\f177"; }

.fi-mobile:before {
  content: "\f178"; }

.fi-monitor:before {
  content: "\f179"; }

.fi-mountains:before {
  content: "\f17a"; }

.fi-music:before {
  content: "\f17b"; }

.fi-next:before {
  content: "\f17c"; }

.fi-no-dogs:before {
  content: "\f17d"; }

.fi-no-smoking:before {
  content: "\f17e"; }

.fi-page-add:before {
  content: "\f17f"; }

.fi-page-copy:before {
  content: "\f180"; }

.fi-page-csv:before {
  content: "\f181"; }

.fi-page-delete:before {
  content: "\f182"; }

.fi-page-doc:before {
  content: "\f183"; }

.fi-page-edit:before {
  content: "\f184"; }

.fi-page-export-csv:before {
  content: "\f185"; }

.fi-page-export-doc:before {
  content: "\f186"; }

.fi-page-export-pdf:before {
  content: "\f187"; }

.fi-page-export:before {
  content: "\f188"; }

.fi-page-filled:before {
  content: "\f189"; }

.fi-page-multiple:before {
  content: "\f18a"; }

.fi-page-pdf:before {
  content: "\f18b"; }

.fi-page-remove:before {
  content: "\f18c"; }

.fi-page-search:before {
  content: "\f18d"; }

.fi-page:before {
  content: "\f18e"; }

.fi-paint-bucket:before {
  content: "\f18f"; }

.fi-paperclip:before {
  content: "\f190"; }

.fi-pause:before {
  content: "\f191"; }

.fi-paw:before {
  content: "\f192"; }

.fi-paypal:before {
  content: "\f193"; }

.fi-pencil:before {
  content: "\f194"; }

.fi-photo:before {
  content: "\f195"; }

.fi-play-circle:before {
  content: "\f196"; }

.fi-play-video:before {
  content: "\f197"; }

.fi-play:before {
  content: "\f198"; }

.fi-plus:before {
  content: "\f199"; }

.fi-pound:before {
  content: "\f19a"; }

.fi-power:before {
  content: "\f19b"; }

.fi-previous:before {
  content: "\f19c"; }

.fi-price-tag:before {
  content: "\f19d"; }

.fi-pricetag-multiple:before {
  content: "\f19e"; }

.fi-print:before {
  content: "\f19f"; }

.fi-prohibited:before {
  content: "\f1a0"; }

.fi-projection-screen:before {
  content: "\f1a1"; }

.fi-puzzle:before {
  content: "\f1a2"; }

.fi-quote:before {
  content: "\f1a3"; }

.fi-record:before {
  content: "\f1a4"; }

.fi-refresh:before {
  content: "\f1a5"; }

.fi-results-demographics:before {
  content: "\f1a6"; }

.fi-results:before {
  content: "\f1a7"; }

.fi-rewind-ten:before {
  content: "\f1a8"; }

.fi-rewind:before {
  content: "\f1a9"; }

.fi-rss:before {
  content: "\f1aa"; }

.fi-safety-cone:before {
  content: "\f1ab"; }

.fi-save:before {
  content: "\f1ac"; }

.fi-share:before {
  content: "\f1ad"; }

.fi-sheriff-badge:before {
  content: "\f1ae"; }

.fi-shield:before {
  content: "\f1af"; }

.fi-shopping-bag:before {
  content: "\f1b0"; }

.fi-shopping-cart:before {
  content: "\f1b1"; }

.fi-shuffle:before {
  content: "\f1b2"; }

.fi-skull:before {
  content: "\f1b3"; }

.fi-social-500px:before {
  content: "\f1b4"; }

.fi-social-adobe:before {
  content: "\f1b5"; }

.fi-social-amazon:before {
  content: "\f1b6"; }

.fi-social-android:before {
  content: "\f1b7"; }

.fi-social-apple:before {
  content: "\f1b8"; }

.fi-social-behance:before {
  content: "\f1b9"; }

.fi-social-bing:before {
  content: "\f1ba"; }

.fi-social-blogger:before {
  content: "\f1bb"; }

.fi-social-delicious:before {
  content: "\f1bc"; }

.fi-social-designer-news:before {
  content: "\f1bd"; }

.fi-social-deviant-art:before {
  content: "\f1be"; }

.fi-social-digg:before {
  content: "\f1bf"; }

.fi-social-dribbble:before {
  content: "\f1c0"; }

.fi-social-drive:before {
  content: "\f1c1"; }

.fi-social-dropbox:before {
  content: "\f1c2"; }

.fi-social-evernote:before {
  content: "\f1c3"; }

.fi-social-facebook:before {
  content: "\f1c4"; }

.fi-social-flickr:before {
  content: "\f1c5"; }

.fi-social-forrst:before {
  content: "\f1c6"; }

.fi-social-foursquare:before {
  content: "\f1c7"; }

.fi-social-game-center:before {
  content: "\f1c8"; }

.fi-social-github:before {
  content: "\f1c9"; }

.fi-social-google-plus:before {
  content: "\f1ca"; }

.fi-social-hacker-news:before {
  content: "\f1cb"; }

.fi-social-hi5:before {
  content: "\f1cc"; }

.fi-social-instagram:before {
  content: "\f1cd"; }

.fi-social-joomla:before {
  content: "\f1ce"; }

.fi-social-lastfm:before {
  content: "\f1cf"; }

.fi-social-linkedin:before {
  content: "\f1d0"; }

.fi-social-medium:before {
  content: "\f1d1"; }

.fi-social-myspace:before {
  content: "\f1d2"; }

.fi-social-orkut:before {
  content: "\f1d3"; }

.fi-social-path:before {
  content: "\f1d4"; }

.fi-social-picasa:before {
  content: "\f1d5"; }

.fi-social-pinterest:before {
  content: "\f1d6"; }

.fi-social-rdio:before {
  content: "\f1d7"; }

.fi-social-reddit:before {
  content: "\f1d8"; }

.fi-social-skillshare:before {
  content: "\f1d9"; }

.fi-social-skype:before {
  content: "\f1da"; }

.fi-social-smashing-mag:before {
  content: "\f1db"; }

.fi-social-snapchat:before {
  content: "\f1dc"; }

.fi-social-spotify:before {
  content: "\f1dd"; }

.fi-social-squidoo:before {
  content: "\f1de"; }

.fi-social-stack-overflow:before {
  content: "\f1df"; }

.fi-social-steam:before {
  content: "\f1e0"; }

.fi-social-stumbleupon:before {
  content: "\f1e1"; }

.fi-social-treehouse:before {
  content: "\f1e2"; }

.fi-social-tumblr:before {
  content: "\f1e3"; }

.fi-social-twitter:before {
  content: "\f1e4"; }

.fi-social-vimeo:before {
  content: "\f1e5"; }

.fi-social-windows:before {
  content: "\f1e6"; }

.fi-social-xbox:before {
  content: "\f1e7"; }

.fi-social-yahoo:before {
  content: "\f1e8"; }

.fi-social-yelp:before {
  content: "\f1e9"; }

.fi-social-youtube:before {
  content: "\f1ea"; }

.fi-social-zerply:before {
  content: "\f1eb"; }

.fi-social-zurb:before {
  content: "\f1ec"; }

.fi-sound:before {
  content: "\f1ed"; }

.fi-star:before {
  content: "\f1ee"; }

.fi-stop:before {
  content: "\f1ef"; }

.fi-strikethrough:before {
  content: "\f1f0"; }

.fi-subscript:before {
  content: "\f1f1"; }

.fi-superscript:before {
  content: "\f1f2"; }

.fi-tablet-landscape:before {
  content: "\f1f3"; }

.fi-tablet-portrait:before {
  content: "\f1f4"; }

.fi-target-two:before {
  content: "\f1f5"; }

.fi-target:before {
  content: "\f1f6"; }

.fi-telephone-accessible:before {
  content: "\f1f7"; }

.fi-telephone:before {
  content: "\f1f8"; }

.fi-text-color:before {
  content: "\f1f9"; }

.fi-thumbnails:before {
  content: "\f1fa"; }

.fi-ticket:before {
  content: "\f1fb"; }

.fi-torso-business:before {
  content: "\f1fc"; }

.fi-torso-female:before {
  content: "\f1fd"; }

.fi-torso:before {
  content: "\f1fe"; }

.fi-torsos-all-female:before {
  content: "\f1ff"; }

.fi-torsos-all:before {
  content: "\f200"; }

.fi-torsos-female-male:before {
  content: "\f201"; }

.fi-torsos-male-female:before {
  content: "\f202"; }

.fi-torsos:before {
  content: "\f203"; }

.fi-trash:before {
  content: "\f204"; }

.fi-trees:before {
  content: "\f205"; }

.fi-trophy:before {
  content: "\f206"; }

.fi-underline:before {
  content: "\f207"; }

.fi-universal-access:before {
  content: "\f208"; }

.fi-unlink:before {
  content: "\f209"; }

.fi-unlock:before {
  content: "\f20a"; }

.fi-upload-cloud:before {
  content: "\f20b"; }

.fi-upload:before {
  content: "\f20c"; }

.fi-usb:before {
  content: "\f20d"; }

.fi-video:before {
  content: "\f20e"; }

.fi-volume-none:before {
  content: "\f20f"; }

.fi-volume-strike:before {
  content: "\f210"; }

.fi-volume:before {
  content: "\f211"; }

.fi-web:before {
  content: "\f212"; }

.fi-wheelchair:before {
  content: "\f213"; }

.fi-widget:before {
  content: "\f214"; }

.fi-wrench:before {
  content: "\f215"; }

.fi-x-circle:before {
  content: "\f216"; }

.fi-x:before {
  content: "\f217"; }

.fi-yen:before {
  content: "\f218"; }

.fi-zoom-in:before {
  content: "\f219"; }

.fi-zoom-out:before {
  content: "\f21a"; }

sub, sup, .datepicker td, .datepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  border: 0;
  font-size: 12px;
  padding: 4px 8px;
  background: #fff;
  cursor: pointer; }

.datepicker {
  display: none;
  position: absolute;
  padding: 4px;
  margin-top: 1px;
  direction: ltr; }
  .datepicker.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    display: none;
    min-width: 160px;
    list-style: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    *border-right-width: 2px;
    *border-bottom-width: 2px;
    color: #333;
    font-family: "Open Sans", "sans-serif";
    font-size: 13px;
    line-height: 18px; }
    .datepicker.dropdown-menu th {
      padding: 4px 5px; }
    .datepicker.dropdown-menu td {
      padding: 4px 5px; }
  .datepicker table {
    border: 0;
    margin: 0; }
    .datepicker table tr td span {
      display: block;
      width: 23%;
      height: 54px;
      line-height: 54px;
      float: left;
      margin: 1%;
      cursor: pointer; }
  .datepicker th, .datepicker td {
    vertical-align: middle; }
  .datepicker td.active.day {
    background: #2ba6cb; }
  .datepicker td.active.year {
    background: #2ba6cb; }
  .datepicker td.old {
    color: #999; }
  .datepicker td.new {
    color: #999; }
  .datepicker td span.active {
    background: #2ba6cb; }
  .datepicker td.day.disabled {
    color: #eee; }
  .datepicker th.active.day {
    background: #2ba6cb; }
  .datepicker th.active.year {
    background: #2ba6cb; }
  .datepicker th.date-switch {
    width: 145px; }
  .datepicker th span.active {
    background: #2ba6cb; }
  .datepicker .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle; }
  .datepicker.days div.datepicker-days {
    display: block; }
  .datepicker.months div.datepicker-months {
    display: block; }
  .datepicker.years div.datepicker-years {
    display: block; }
  .datepicker thead tr:first-child th {
    cursor: pointer; }
    .datepicker thead tr:first-child th.cw {
      cursor: default;
      background-color: transparent; }
  .datepicker tfoot tr:first-child th {
    cursor: pointer; }

.datepicker-inline {
  width: 220px; }

.datepicker-rtl {
  direction: rtl; }
  .datepicker-rtl table tr td span {
    float: right; }

.datepicker-dropdown {
  top: 0;
  left: 0; }
  .datepicker-dropdown:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -7px;
    left: 6px; }
  .datepicker-dropdown:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    position: absolute;
    top: -6px;
    left: 7px; }

.datepicker > div {
  display: none; }

.datepicker-dropdown::before {
  display: none; }

.datepicker-dropdown::after {
  display: none; }

.datepicker-close {
  position: absolute;
  top: -30px;
  right: 0;
  width: 15px;
  height: 30px;
  padding: 0;
  display: none; }

.table-striped .datepicker table tr td {
  background-color: transparent; }

.table-striped .datepicker table tr th {
  background-color: transparent; }

table.dataTable {
  clear: both;
  margin: 0.5em 0 !important;
  max-width: none !important;
  width: 100%; }
  table.dataTable td, table.dataTable th {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box; }
  table.dataTable.nowrap th, table.dataTable.nowrap td {
    white-space: nowrap; }

div.dataTables_wrapper div.dataTables_length label {
  float: left;
  text-align: left;
  margin-bottom: 0; }

div.dataTables_wrapper div.dataTables_length select {
  width: 75px;
  margin-bottom: 0; }

div.dataTables_wrapper div.dataTables_filter label {
  float: right;
  margin-bottom: 0; }

div.dataTables_wrapper div.dataTables_filter input {
  display: inline-block !important;
  width: auto !important;
  margin-bottom: 0;
  margin-left: 0.5em; }

div.dataTables_wrapper div.dataTables_info {
  padding-top: 2px; }

div.dataTables_wrapper div.dataTables_paginate {
  float: right;
  margin: 0; }

table.dataTable thead th, table.dataTable thead td {
  padding-right: 1.5rem; }

table.dataTable thead th:active, table.dataTable thead td:active {
  outline: none; }

table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc {
  cursor: pointer; }

table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc_disabled {
  background-repeat: no-repeat;
  background-position: center right; }

table.dataTable thead .sorting {
  background-image: url("/img/sort_both.png"); }

table.dataTable thead .sorting_asc {
  background-image: url("/img/sort_asc.png"); }

table.dataTable thead .sorting_desc {
  background-image: url("/img/sort_desc.png"); }

table.dataTable thead .sorting_asc_disabled {
  background-image: url("/img/sort_asc_disabled.png"); }

table.dataTable thead .sorting_desc_disabled {
  background-image: url("/img/sort_desc_disabled.png"); }

div.dataTables_scrollHead table {
  margin-bottom: 0 !important; }

div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important; }
  div.dataTables_scrollBody table tbody tr:first-child th, div.dataTables_scrollBody table tbody tr:first-child td {
    border-top: none; }

div.dataTables_scrollFoot table {
  margin-top: 0 !important;
  border-top: none; }

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }
  .select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    user-select: none; }
    .select2-container .select2-selection--single .select2-selection__rendered {
      display: block;
      padding-left: 8px;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .select2-container .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    user-select: none; }
    .select2-container .select2-selection--multiple .select2-selection__rendered {
      display: inline-block;
      overflow: hidden;
      padding-left: 8px;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .select2-container .select2-search--inline {
    float: left; }
    .select2-container .select2-search--inline .select2-search__field {
      box-sizing: border-box;
      border: none;
      font-size: 100%;
      margin-top: 5px; }

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

.select2-results__option {
  padding: 6px;
  user-select: none; }

.select2-results__option[aria-selected] {
  cursor: pointer; }

.select2-container--open .select2-dropdown {
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display: block;
  padding: 4px; }
  .select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box; }
  .select2-search--dropdown.select2-search--hide {
    display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: white;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.select2-container--default .select2-selection--single {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px; }
  .select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold; }
  .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #999; }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px; }
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      border-color: #888 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      height: 0;
      left: 50%;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }
  .select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none; }

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text; }
  .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%; }
  .select2-container--default .select2-selection--multiple .select2-selection__placeholder {
    color: #999;
    margin-top: 5px;
    float: left; }
  .select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-top: 5px;
    margin-right: 10px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #999;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px; }
    .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #333; }

.select2-container--default .select2-results__option[role=group] {
  padding: 0; }

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white; }

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em; }
    .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
      margin-left: -2em;
      padding-left: 3em; }
      .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
        margin-left: -3em;
        padding-left: 4em; }
        .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
          margin-left: -4em;
          padding-left: 5em; }
          .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
            margin-left: -5em;
            padding-left: 6em; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder {
  float: right; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--classic .select2-selection--single {
  background-color: #f6f6f6;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: linear-gradient(to bottom, white 50%, #eee 100%);
  background-repeat: repeat-x;
  filter: progid:dximagetransform.microsoft.gradient(startcolorstr='$white', endcolorstr='$color_gallery_approx', gradienttype=0); }
  .select2-container--classic .select2-selection--single:focus {
    border: 1px solid #5897fb; }
  .select2-container--classic .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px; }
  .select2-container--classic .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-right: 10px; }
  .select2-container--classic .select2-selection--single .select2-selection__placeholder {
    color: #999; }
  .select2-container--classic .select2-selection--single .select2-selection__arrow {
    background-color: #ddd;
    border: none;
    border-left: 1px solid #aaa;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
    background-image: linear-gradient(to bottom, #eee 50%, #cccccc 100%);
    background-repeat: repeat-x;
    filter: progid:dximagetransform.microsoft.gradient(startcolorstr='$color_gallery_approx', endcolorstr='$color_celeste_approx', gradienttype=0); }
    .select2-container--classic .select2-selection--single .select2-selection__arrow b {
      border-color: #888 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      height: 0;
      left: 50%;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0; }

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb; }
  .select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: transparent;
    border: none; }
    .select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
      border-color: transparent transparent #888 transparent;
      border-width: 0 4px 5px 4px; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: linear-gradient(to bottom, white 0, #eee 50%);
  background-repeat: repeat-x;
  filter: progid:dximagetransform.microsoft.gradient(startcolorstr='$white', endcolorstr='$color_gallery_approx', gradienttype=0); }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: linear-gradient(to bottom, #eee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:dximagetransform.microsoft.gradient(startcolorstr='$color_gallery_approx', endcolorstr='$white', gradienttype=0); }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb; }

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0; }
  .select2-container--classic .select2-selection--multiple:focus {
    border: 1px solid #5897fb; }
  .select2-container--classic .select2-selection--multiple .select2-selection__rendered {
    list-style: none;
    margin: 0;
    padding: 0 5px; }
  .select2-container--classic .select2-selection--multiple .select2-selection__clear {
    display: none; }
  .select2-container--classic .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px; }
  .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
    color: #888;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px; }
    .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #555; }

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent; }

.select2-container--classic .select2-dropdown--above {
  border-bottom: none; }

.select2-container--classic .select2-dropdown--below {
  border-top: none; }

.select2-container--classic .select2-results__option[role=group] {
  padding: 0; }

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey; }

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white; }

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0; }

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0; }

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px; }

.call-comment {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: solid 1px #D2D6DE;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 10px; }
  .call-comment .user-comment {
    margin-bottom: 5px;
    border-bottom: solid 1px #D2D6DE;
    font-size: small; }
  .call-comment .content-comment {
    padding: 0 5px;
    margin: 0; }
  .call-comment a.delete {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #d8d8d8; }
    .call-comment a.delete:hover {
      color: #5e5e5e; }

.answer {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: solid 1px #D2D6DE;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 10px; }
  .answer.waiting {
    border: solid 2px #f04124; }
  .answer.matched {
    border: solid 2px #d2d800; }
  .answer.closed {
    border: solid 2px #7fd865; }
  .answer .user-answer {
    margin-bottom: 5px;
    border-bottom: solid 1px #D2D6DE;
    font-size: small; }
  .answer .content-answer {
    padding: 0 5px;
    margin: 0; }
  .answer a.match {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #d8d8d8; }
    .answer a.match:hover {
      color: #d2d800; }
    .answer a.match.matched.disabled {
      color: #d2d800;
      cursor: default; }
      .answer a.match.matched.disabled.waiting {
        color: #f04124; }
  .answer a.closedeal {
    position: absolute;
    right: 25px;
    top: 5px;
    color: #d8d8d8; }
    .answer a.closedeal:hover {
      color: #d2d800; }
    .answer a.closedeal.closed.disabled {
      color: #7fd865;
      cursor: default; }

.profile-feedback {
  padding-top: 10px; }

.user-profile-item {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: solid 1px #D2D6DE;
  padding: 5px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 10px; }
  .user-profile-item .user-profile-head {
    border-bottom: dotted 1px #D2D6DE;
    margin-bottom: 5px; }
  .user-profile-item .user-profile-name {
    margin-bottom: 5px;
    font-size: small;
    vertical-align: middle;
    height: 40px; }
  .user-profile-item .user-profile-photo {
    width: 40px;
    vertical-align: middle; }
    .user-profile-item .user-profile-photo .profile-photo {
      width: 30px;
      height: 30px;
      background-size: 30px auto;
      top: 0px; }
  .user-profile-item .user-details {
    padding: 0 5px;
    margin: 0; }
    .user-profile-item .user-details ul {
      display: inline;
      list-style: none; }
      .user-profile-item .user-details ul li {
        display: inline;
        border: solid 1px #D2D6DE;
        margin-right: 5px;
        padding: 1px 4px; }
  .user-profile-item .user-feedback {
    width: 150px; }
  .user-profile-item .user-actions a {
    color: #d8d8d8; }
    .user-profile-item .user-actions a:hover {
      color: #008cba; }
    .user-profile-item .user-actions a.active {
      color: #008cba; }

.profile-photo {
  position: relative;
  top: 5px;
  width: 35px;
  height: 35px;
  background-size: 35px auto;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%; }

.feedback {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: solid 1px #D2D6DE;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 10px; }
  .feedback .user-feedback {
    margin-bottom: 5px;
    border-bottom: solid 1px #D2D6DE;
    font-size: small; }
  .feedback .content-feedback {
    padding: 0 5px;
    margin: 0; }
  .feedback .content-source {
    display: none; }
  .feedback .feedback-stars {
    position: absolute;
    right: 5px;
    top: 5px; }

.feedback-stars {
  font-size: larger;
  color: rgba(245, 159, 26, 0.8);
  text-shadow: 1px 4px 6px #FFF,0 0 0 #000,1px 4px 6px #FFF; }
  .feedback-stars .disabled {
    color: #d8d8d8; }
  .feedback-stars.input i:hover {
    cursor: pointer; }

#notifications-topbar {
  position: relative; }
  #notifications-topbar .notifications-count {
    position: absolute;
    top: 5px;
    right: 25px;
    background-color: red;
    color: white;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    font-size: 10px;
    line-height: 16px;
    width: 16px;
    height: 16px;
    text-align: center;
    box-sizing: border-box; }

.top-bar-section .profile-selection {
  background-color: #00a0cd; }
  .top-bar-section .profile-selection a:not(.button) {
    background-color: transparent !important; }
  .top-bar-section .profile-selection .dropdown li {
    box-sizing: border-box; }
    .top-bar-section .profile-selection .dropdown li:hover {
      background-color: #222222 !important; }
    .top-bar-section .profile-selection .dropdown li a {
      color: white; }
  .top-bar-section .profile-selection .inline {
    display: inline;
    white-space: nowrap; }
  .top-bar-section .profile-selection .name {
    background-color: #00a0cd;
    box-sizing: border-box;
    padding: 0 5px; }
  .top-bar-section .profile-selection #menu-photo, .top-bar-section .profile-selection .menu-photo {
    position: relative;
    top: 5px;
    width: 35px;
    height: 35px;
    background-size: 35px auto;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%; }
    .top-bar-section .profile-selection #menu-photo a, .top-bar-section .profile-selection .menu-photo a {
      padding: 15px; }
  .top-bar-section .profile-selection .welcome {
    display: inline-block;
    font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 0.8125rem;
    font-weight: normal; }
    .top-bar-section .profile-selection .welcome a {
      color: black; }
  .top-bar-section .profile-selection #menu-photo-add {
    background: url("/img/add-icon.png") center no-repeat;
    background-size: 30px auto; }

.container {
  margin-top: 20px; }

table.editable tr:not(.disabled),
table.picktable tr:not(.disabled) {
  cursor: pointer; }
  table.editable tr:not(.disabled):hover td,
  table.picktable tr:not(.disabled):hover td {
    background-color: lightsteelblue; }
  table.editable tr:not(.disabled) th.selector > input, table.editable tr:not(.disabled) td.selector > input,
  table.picktable tr:not(.disabled) th.selector > input,
  table.picktable tr:not(.disabled) td.selector > input {
    margin: 0;
    vertical-align: middle; }
  table.editable tr:not(.disabled).red td, table.editable tr:not(.disabled).red a,
  table.picktable tr:not(.disabled).red td,
  table.picktable tr:not(.disabled).red a {
    color: #f04124; }

table.editable tr.disabled,
table.picktable tr.disabled {
  font-style: italic; }
  table.editable tr.disabled td,
  table.picktable tr.disabled td {
    background-color: #efefef; }

button, a.button {
  min-width: 100px; }
  button.delete, a.button.delete {
    background-color: #ff8b39; }

#side-actions a.button {
  width: 100%; }

.button.disabled, .button[disabled] {
  opacity: 0.5; }

.select2-container {
  margin: 0 0 1rem; }

form div.has-error {
  color: red; }
  form div.has-error label {
    color: red; }
  form div.has-error input,
  form div.has-error .select2-container.select2 {
    border-color: red;
    margin-bottom: 0; }
  form div.has-error .select2-container.select2 a,
  form div.has-error .select2-container-multi ul {
    border-color: red; }
  form div.has-error .help-block {
    margin: 0 0 0.5rem;
    font-size: small; }

.likeP {
  margin-bottom: 1.25rem; }

.rounded-panel {
  border: solid 1px #D2D6DE; }

.target {
  background-color: yellow; }

#notifications-topbar li:not(.has-form) a:not(.button).notification {
  line-height: 15px;
  padding: 12px 0.9375rem; }

#notifications-topbar .dropdown {
  max-height: 250px;
  overflow-y: auto; }
  #notifications-topbar .dropdown li a {
    white-space: normal; }

.overpane {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(239, 239, 239, 0.9);
  padding: 2rem;
  z-index: 2000;
  transition: 250ms cubic-bezier(0.7, 0, 0.3, 1) transform;
  transform: translateY(100%); }
  .overpane.show {
    transform: translateY(0); }
  .overpane .row {
    margin: 0 auto;
    max-width: 62.5rem;
    width: 100%; }

.file-upload {
  position: relative;
  overflow: hidden;
  margin: 10px; }

.file-upload input.file-input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0); }

.addToCart a {
  font-size: large; }

div .table {
  display: table;
  width: 100%; }
  div .table .row {
    display: table-row; }
    div .table .row .cell {
      display: table-cell;
      box-sizing: border-box;
      padding: 10px; }
      div .table .row .cell.text-right {
        text-align: right; }
      div .table .row .cell.text-center {
        text-align: center; }
      div .table .row .cell.no-padding {
        padding: 0; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.select2.icon {
  width: 20px; }

.disclaim {
  font-size: smaller; }

span.disabled {
  color: lightgrey; }

.border {
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  padding: 5px; }

.orders h4 {
  display: inline;
  margin-right: 10px; }

.orders .order {
  display: inline;
  margin-right: 10px; }
  .orders .order.disabled a {
    color: #c5c5c5; }

.dashboard-icon {
  font-size: 7.5em; }

.not-charged td {
  color: lightgrey; }
